import React, { FC } from 'react';

interface Props {
  header: string;
}

const CenteredSheetLayout: FC<Props> = ({ header, children }) => {
  return (
    <div className="w-1/2 m-auto shadow-md p-12 text-center bg-white">
      <h1 className="text-3xl font-bold pb-8">{header}</h1>
      {children}
    </div>
  );
};

export default CenteredSheetLayout;

import { Field } from 'formik';
import React, { ComponentProps, FC } from 'react';

type CheckBoxProps = {
  label: string;
  error?: boolean;
} & ComponentProps<typeof Field>;

const CheckBox: FC<CheckBoxProps> = ({ label, name, value }) => {
  return (
    <label className="block">
      <Field type="checkbox" name={name} value={value} />
      {label}
    </label>
  );
};

export default CheckBox;

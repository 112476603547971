import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ApolloProvider } from '@apollo/react-hooks';
import React, { FC } from 'react';

import { AUTH_TOKEN } from '../PrivateRoute/PrivateRoute';

const BordenApolloProvider: FC = ({ children }) => {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_SERVICE_URL}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: localStorage.getItem(AUTH_TOKEN) ?? '',
      },
    };
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default BordenApolloProvider;

import { gql } from '@apollo/react-hooks';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, MouseEventHandler, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { NavHeaderUser } from '../../generated/NavHeaderUser';
import { ADMIN_PATH, EXCHANGE_PATH, HOME_PATH } from '../../paths';
import AccountDropdown from './Components/AccountDropdown';

interface NavHeaderProps {
  user: NavHeaderUser;
}

const NavHeader: FC<NavHeaderProps> = ({ user }) => {
  3;
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const handleAccountToggle: MouseEventHandler = (event) => {
    event.preventDefault();
    setShowAccountDropdown(!showAccountDropdown);
  };

  const handleAccountItemClick = () => {
    setShowAccountDropdown(false);
  };

  return (
    <div className="w-full bg-green text-white flex justify-between px-8 py-4">
      <ul className="flex">
        <li>
          <NavLink to={HOME_PATH} exact>
            Home
          </NavLink>
        </li>
        {user.admin && (
          <>
            <li className="pl-4">
              <NavLink to={EXCHANGE_PATH} exact>
                Exchanges
              </NavLink>
            </li>
            <li className="pl-4">
              <NavLink to={ADMIN_PATH} exact>
                Admin
              </NavLink>
            </li>
          </>
        )}
      </ul>
      <div>
        <FontAwesomeIcon
          icon={faUserCircle}
          size="2x"
          onClick={handleAccountToggle}
          className="cursor-pointer hover:text-teal"
        />
        {showAccountDropdown && <AccountDropdown onItemClick={handleAccountItemClick} />}
      </div>
    </div>
  );
};

export const NavHeaderFragmentGql = gql`
  fragment NavHeaderUser on User {
    id
    admin
  }
`;

export default NavHeader;

import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import NotFound from '../../Pages/NotFound';
import UserCheck from './UserCheck';

export const AUTH_TOKEN = 'auth_bearer';

type PrivateRouteParams = ComponentPropsWithoutRef<typeof Route>;

const PrivateRoute: FC<PrivateRouteParams> = ({ component: Component, ...rest }: RouteProps) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!Component) return <NotFound />;
        return (
          <UserCheck>
            <Component {...props} />
          </UserCheck>
        );
      }}
    />
  );
};

export default PrivateRoute;

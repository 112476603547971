import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/react-hooks';
import { Formik } from 'formik';
import { parse } from 'query-string';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { object, ref, string } from 'yup';

import Loading from '../../Components/Loading';
import { RegisterCreateUser, RegisterCreateUserVariables } from '../../generated/RegisterCreateUser';
import { RegisterValidToken, RegisterValidTokenVariables } from '../../generated/RegisterValidToken';
import { LOGIN_PATH } from '../../paths';
import InvalidToken from './Components/InvalidToken';
import RegisterForm from './Components/RegisterForm';

export interface RegisterFormValues {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
}

const Register: FC = () => {
  const { search } = useLocation();
  const params = parse(search);
  const token = (params.t as string) || '';

  const { data, loading, error } = useQuery<RegisterValidToken, RegisterValidTokenVariables>(RegisterValidTokenGql, {
    variables: {
      token,
    },
  });
  const [createUser] = useMutation<RegisterCreateUser, RegisterCreateUserVariables>(RegisterCreateUserGql);

  const initialValues: RegisterFormValues = {
    email: data?.invitedUser.email || '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
  };

  const handleSubmit = async (values: RegisterFormValues) => {
    const { errors } = await createUser({
      variables: {
        token,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
      },
    });

    if (!errors) {
      window.location.href = LOGIN_PATH;
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>Register</title>
      </Helmet>
      <div>
        {error && <InvalidToken />}
        {data && (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={object().shape({
              firstName: string().optional(),
              lastName: string().optional(),
              password: string().required().min(5),
              confirmPassword: string()
                .oneOf([ref('password')], 'Passwords must match')
                .required(),
            })}
          >
            {({ touched, errors }) => {
              return <RegisterForm touched={touched} errors={errors} />;
            }}
          </Formik>
        )}
      </div>
    </>
  );
};

const RegisterValidTokenGql = gql`
  query RegisterValidToken($token: String!) {
    invitedUser(token: $token) {
      email
    }
  }
`;

const RegisterCreateUserGql = gql`
  mutation RegisterCreateUser($token: String!, $password: String!, $firstName: String, $lastName: String) {
    createUser(token: $token, password: $password, firstName: $firstName, lastName: $lastName) {
      id
    }
  }
`;

export default Register;

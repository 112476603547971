import { gql, useMutation, useQuery } from '@apollo/client';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';

import Button from '../../Components/Button';
import Card from '../../Components/Card';
import PageLayout from '../../Components/PageLayout';
import {
  ExchangeCreateExchangeMutation,
  ExchangeCreateExchangeMutationVariables,
} from '../../generated/ExchangeCreateExchangeMutation';
import { ExchangesExchangeQuery } from '../../generated/ExchangesExchangeQuery';
import ExchangeCreateForm, { ExchangeCreateFormValues } from './Components/ExchangeCreateForm';
import ExchangeTable, { ExchangeTableFragmentGql } from './Components/ExchangeTable';

const Exchanges: FC = () => {
  const [showForm, setShowForm] = useState(false);

  const { loading, data } = useQuery<ExchangesExchangeQuery>(ExchangesExchangeGql);
  const [createExchange] = useMutation<ExchangeCreateExchangeMutation, ExchangeCreateExchangeMutationVariables>(
    ExchangesCreateExchangeGql,
    {
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            exchanges: (existingExchanges) => {
              const newExchangeRef = cache.writeFragment({
                data: data?.createExchange,
                fragment: ExchangeTableFragmentGql,
              });
              return [...existingExchanges, newExchangeRef];
            },
          },
        });
      },
    },
  );

  const handleSubmit = async (values: ExchangeCreateFormValues) => {
    await createExchange({ variables: { name: values.name, limit: values.limit * 100 } });

    setShowForm(false);
  };

  return (
    <PageLayout>
      <Helmet>
        <title>Exchanges</title>
      </Helmet>
      <Card header="Exchanges">
        <ExchangeTable loading={loading} exchanges={data?.exchanges} />
        {showForm ? (
          <ExchangeCreateForm onCancel={() => setShowForm(false)} onSubmit={handleSubmit} />
        ) : (
          <Button
            onClick={() => {
              setShowForm(true);
            }}
            color="secondary"
          >
            Create new exchange
          </Button>
        )}
      </Card>
    </PageLayout>
  );
};

const ExchangesCreateExchangeGql = gql`
  mutation ExchangeCreateExchangeMutation($name: String!, $limit: Int) {
    createExchange(name: $name, limitInCents: $limit) {
      id
      ...ExchangeTableFragment
    }
  }

  ${ExchangeTableFragmentGql}
`;

const ExchangesExchangeGql = gql`
  query ExchangesExchangeQuery {
    exchanges {
      id
      name
      limitInCents
      ...ExchangeTableFragment
    }
  }

  ${ExchangeTableFragmentGql}
`;

export default Exchanges;

import React, { FC, ReactNode } from 'react';

import Loading from '../Loading';
import Cell from './components/Cell';
import Row from './components/Row';

interface Column {
  name: string;
  value?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform?: (value: any) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderCell?: (entry: any) => ReactNode;
}

interface TableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entries: any[];
  columns: Column[];
  loading: boolean;
}

const Table: FC<TableProps> = ({ loading, entries, columns }) => {
  return (
    <table className="w-full mb-8 text-left border border-green rounded-sm">
      <tbody>
        <Row>
          {columns.map((column) => {
            return (
              <th className="p-2 border bg-green text-white" key={column.name}>
                {column.name}
              </th>
            );
          })}
        </Row>

        {loading ? (
          <tr className="text-center">
            <td colSpan={columns.length}>
              <Loading />
            </td>
          </tr>
        ) : (
          <>
            {entries.length === 0 &&
              [1, 2, 3].map((key) => {
                return (
                  <Row key={key}>
                    {columns.map((column) => {
                      return <Cell key={column.name}></Cell>;
                    })}
                  </Row>
                );
              })}
            {entries.map((entry) => {
              return (
                <Row key={entry.id as string}>
                  {columns.map((column) => {
                    return (
                      <Cell key={column.name}>
                        {column.renderCell && column.renderCell(entry)}
                        {column.value &&
                          (column.transform ? column.transform(entry[column.value]) : entry[column.value])}
                      </Cell>
                    );
                  })}
                </Row>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
};

export default Table;

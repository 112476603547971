import { gql, useLazyQuery } from '@apollo/react-hooks';
import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { object, string } from 'yup';

import Button from '../../../../Components/Button';
import FormField from '../../../../Components/FormField';
import { InviteUser as InviteUserQuery, InviteUserVariables } from '../../../../generated/InviteUser';
import { REGISTER_PATH } from '../../../../paths';

interface Values {
  email: string;
}

const InviteUser: FC = () => {
  const [generateToken, { data }] = useLazyQuery<InviteUserQuery, InviteUserVariables>(inviteUserQueryGql);
  const handleSubmit = (values: Values) => {
    generateToken({ variables: values });
  };

  return (
    <>
      <Formik<Values>
        initialValues={{ email: '' }}
        onSubmit={handleSubmit}
        validationSchema={object().shape({
          email: string().required('Email is required'),
        })}
      >
        <Form className="flex">
          <FormField className="w-auto" type="email" name="email" label="New User's Email" />
          <Button className="w-auto" color="secondary" type="submit">
            Generate Link
          </Button>
        </Form>
      </Formik>
      {data?.userToken && (
        <Link
          to={`${REGISTER_PATH}?t=${data.userToken}`}
        >{`${window.location.protocol}//${window.location.hostname}${REGISTER_PATH}?t=${data.userToken}`}</Link>
      )}
    </>
  );
};

export default InviteUser;

const inviteUserQueryGql = gql`
  query InviteUser($email: String) {
    userToken(email: $email)
  }
`;

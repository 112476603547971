import classNames from 'classnames';
import React, { FC } from 'react';

interface CardProps {
  className?: string;
  header?: string;
}

const Card: FC<CardProps> = ({ children, className, header }) => (
  <div className={classNames(className, 'bg-white p-8 rounded shadow my-8')}>
    {header && <h2 className="text-2xl font-bold pb-8">{header}</h2>}
    {children}
  </div>
);

export default Card;

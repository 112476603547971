import { gql, useMutation } from '@apollo/client';
import React, { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import CenteredSheetLayout from '../../Components/CenteredSheetLayout';
import {
  PasswordResetResetUserPassword,
  PasswordResetResetUserPasswordVariables,
} from '../../generated/PasswordResetResetUserPassword';
import PasswordResetForm, { PasswordResetFormValues } from './Components/PasswordResetForm';

interface RouteParams {
  token: string;
}

const PasswordReset: FC = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submissionFailed, setSubmissionFailed] = useState(false);
  const { token } = useParams<RouteParams>();
  const { push } = useHistory();

  const [resetUserPassword] = useMutation<PasswordResetResetUserPassword, PasswordResetResetUserPasswordVariables>(
    PasswordResetResetUserPasswordGql,
  );

  const handleSubmit = async ({ token, newPassword }: PasswordResetFormValues) => {
    setSubmissionFailed(false);

    try {
      const response = await resetUserPassword({ variables: { token, newPassword } });

      if (response.data?.resetUserPassword) {
        setFormSubmitted(true);
        setTimeout(() => {
          push('/login');
        }, 1000);
      } else {
        setSubmissionFailed(true);
      }
    } catch {
      setSubmissionFailed(true);
    }
  };

  return (
    <CenteredSheetLayout header="Reset Password">
      {submissionFailed && (
        <p className="mb-4">
          There was an issue processing your password reset. If this issue persists, please contact support.
        </p>
      )}
      {formSubmitted ? (
        <p>Your password has been reset. Redirecting to login...</p>
      ) : (
        <PasswordResetForm initialValues={{ token, newPassword: '' }} onSubmit={handleSubmit} />
      )}
    </CenteredSheetLayout>
  );
};

const PasswordResetResetUserPasswordGql = gql`
  mutation PasswordResetResetUserPassword($token: String!, $newPassword: String!) {
    resetUserPassword(resetToken: $token, password: $newPassword)
  }
`;

export default PasswordReset;

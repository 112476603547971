import classNames from 'classnames';
import React, { ButtonHTMLAttributes, FC } from 'react';
import { Link } from 'react-router-dom';

type ButtonProps = {
  color: 'primary' | 'secondary' | 'destructive';
  submitting?: boolean;
  to?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FC<ButtonProps> = ({ to, children, color, className, submitting = false, ...props }) => {
  let colorClass = classNames('bg-green', 'text-white');

  if (color == 'secondary') {
    colorClass = classNames('bg-white', 'border-teal', 'border-2');
  } else if (color === 'destructive') {
    colorClass = classNames('bg-red', 'text-white');
  }

  const classes = classNames(colorClass, className, 'rounded-full', 'py-2', 'px-4', 'inline-block');

  if (to) {
    return (
      <Link to={to} className={classes}>
        {children as string}
      </Link>
    );
  }

  return (
    <button className={classes} {...props}>
      {submitting ? 'Submitting...' : children}
    </button>
  );
};

export default Button;

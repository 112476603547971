import React, { FC } from 'react';

import Card from '../../../Components/Card';

const EmptyAssignments: FC = () => {
  return (
    <Card className="text-center">
      <p>
        Looks like it&apos;s not Christmas time just yet!
        <br />
        (If it is please bug John...)
      </p>
    </Card>
  );
};

export default EmptyAssignments;

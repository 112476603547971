import { gql, useQuery } from '@apollo/react-hooks';
import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';

import { UserCheckCurrentUserQuery } from '../../generated/UserCheckCurrentUserQuery';
import { HOME_PATH, LOGIN_PATH } from '../../paths';
import Loading from '../Loading';

interface UserCheckProps {
  checkAdmin?: boolean;
}

const UserCheck: FC<UserCheckProps> = ({ checkAdmin, children }) => {
  const { loading, data } = useQuery<UserCheckCurrentUserQuery>(UserCheckCurrentUserGQL);

  if (loading) return <Loading />;
  if (checkAdmin && !data?.currentUser.admin) return <Redirect to={HOME_PATH} />;
  if (data?.currentUser) return <>{children}</>;

  return <Redirect to={LOGIN_PATH} />;
};

const UserCheckCurrentUserGQL = gql`
  query UserCheckCurrentUserQuery {
    currentUser {
      id
      admin
    }
  }
`;

export default UserCheck;

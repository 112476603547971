import { gql } from '@apollo/client';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FC } from 'react';

import { AUTH_TOKEN } from '../../../../Components/PrivateRoute';
import { DiscordIntegrationDiscordUser } from '../../../../generated/DiscordIntegrationDiscordUser';
import discordLogo from '../../../../images/Discord-Logo-White.png';

interface DiscordIntegrationProps {
  discordUser: DiscordIntegrationDiscordUser | null;
}

const DiscordIntegration: FC<DiscordIntegrationProps> = ({ discordUser }) => {
  const containerClass = 'inline-flex items-center bg-discord text-white font-discord rounded p-4 text-xl';
  if (discordUser) {
    return (
      <div className={classNames(containerClass, ' cursor-default')}>
        <img src={discordLogo} className="w-16 pr-2" />
        <FontAwesomeIcon icon={faCheck} className="text-green mr-1" />
        Account Linked
      </div>
    );
  }

  return (
    <a
      className={classNames(containerClass, 'cursor-pointer')}
      href={`${process.env.REACT_APP_SERVICE_URL}/discord/oauth?state=${localStorage.getItem(AUTH_TOKEN)}`}
    >
      <img src={discordLogo} className="w-16 pr-2" />
      Link your Account
    </a>
  );
};

export const DiscordIntegrationFragmentGql = gql`
  fragment DiscordIntegrationDiscordUser on DiscordUser {
    id
    username
    discriminator
  }
`;

export default DiscordIntegration;

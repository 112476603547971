import { useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/react-hooks';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import AssignmentDisplay from '../../Components/AssignmentDisplay/AssignmentDisplay';
import PageLayout from '../../Components/PageLayout';
import { HomeCurrentUserQuery, HomeCurrentUserQueryVariables } from '../../generated/HomeCurrentUserQuery';
import centsToCurrency from '../../utils/centsToCurrency';
import { EmptyAssignments } from './Components';

const Home: FC = () => {
  const { data, error, loading } = useQuery<HomeCurrentUserQuery, HomeCurrentUserQueryVariables>(HomeCurrentUserGql, {
    variables: { year: new Date().getFullYear() },
  });

  if (error) return <>Error</>;
  if (loading || !data) return <>Loading</>;

  return (
    <PageLayout>
      <Helmet>
        <title>Home</title>
      </Helmet>
      {data.currentUser.giverExchangeEntries.map((entry) => {
        return (
          <AssignmentDisplay
            exchangeName={entry.exchange.name}
            spendingLimit={centsToCurrency(entry.exchange.limitInCents)}
            userName={`${entry.receiver.firstName} ${entry.receiver.lastName}`}
            key={entry.id}
          />
        );
      })}
      {data.currentUser.giverExchangeEntries.length == 0 && <EmptyAssignments />}
    </PageLayout>
  );
};

const HomeCurrentUserGql = gql`
  query HomeCurrentUserQuery($year: Int) {
    currentUser {
      id
      email
      giverExchangeEntries(year: $year) {
        id
        exchange {
          id
          name
          limitInCents
        }
        receiver {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export default Home;

import { gql, useLazyQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import Card from '../../Components/Card';
import Loading from '../../Components/Loading';
import PageLayout from '../../Components/PageLayout';
import { ExchangeExchange, ExchangeExchangeVariables } from '../../generated/ExchangeExchange';
import AssignedExchange, { AssignedExchangeExchangeFragmentGql } from './components/AssignedExchange';
import UnassignedExchange from './components/UnassignedExchange';
import { UnassignedExchangeExchangeFragmentGql } from './components/UnassignedExchange/UnassignedExchange';

const Exchange: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [getExchange, { called, loading, data }] = useLazyQuery<ExchangeExchange, ExchangeExchangeVariables>(
    ExchangeExchangeGql,
  );

  useEffect(() => {
    const effect = async () => {
      if (id) {
        await getExchange({ variables: { id } });
      }
    };

    effect();
  }, [id]);

  if (loading) {
    return <Loading includeLayout={true} />;
  }

  const dataPresent = called && data;
  if (!dataPresent) {
    return <PageLayout />;
  }

  return (
    <PageLayout>
      <Helmet>
        <title>{data.exchange.name}</title>
      </Helmet>
      <Card header={data.exchange.name}>
        {data.exchange.entries.length > 0 ? (
          <AssignedExchange exchange={data.exchange} />
        ) : (
          <UnassignedExchange exchange={data.exchange} />
        )}
      </Card>
    </PageLayout>
  );
};

const ExchangeExchangeGql = gql`
  query ExchangeExchange($id: String!) {
    exchange(id: $id) {
      id
      name
      entries {
        id
      }
      ...AssignedExchangeExchangeFragment
      ...UnassignedExchangeExchangeFragment
    }
  }

  ${AssignedExchangeExchangeFragmentGql}
  ${UnassignedExchangeExchangeFragmentGql}
`;

export default Exchange;

import { gql } from '@apollo/client';
import React, { FC } from 'react';

import { AssignedExchangeExchangeFragment } from '../../../generated/AssignedExchangeExchangeFragment';
import fullNameForUser from '../../../utils/fullNameForUser';

interface AssignedExchangeProps {
  exchange: AssignedExchangeExchangeFragment;
}

const AssignedExchange: FC<AssignedExchangeProps> = ({ exchange }) => {
  return (
    <div>
      <h3>Entries:</h3>
      <ul>
        {exchange.entries.map((entry) => {
          return (
            <li key={entry.id}>
              {fullNameForUser(entry.giver)} is shopping for {fullNameForUser(entry.receiver)}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const AssignedExchangeExchangeFragmentGql = gql`
  fragment AssignedExchangeExchangeFragment on Exchange {
    id
    entries {
      id
      giver {
        id
        firstName
        lastName
      }
      receiver {
        id
        firstName
        lastName
      }
    }
  }
`;

export default AssignedExchange;

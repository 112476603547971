import React, { FC } from 'react';

import presentImg from '../../images/present.png';
import Card from '../Card';

interface AssignmentDisplayProps {
  exchangeName: string;
  userName: string;
  spendingLimit: string;
}

const AssignmentDisplay: FC<AssignmentDisplayProps> = ({ exchangeName, userName, spendingLimit }) => {
  return (
    <Card className="flex">
      <img className="w-32 h-32 mx-auto md:mx-0" src={presentImg} alt={exchangeName} />
      <div className="my-auto ml-8">
        <h3 className="text-4xl">{exchangeName}</h3>
        <p className="text-xl">
          You have: <strong>{userName}</strong>
        </p>
        <p>Spending limit: ${spendingLimit}</p>
      </div>
    </Card>
  );
};

export default AssignmentDisplay;

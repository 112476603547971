import { Form, FormikState } from 'formik';
import React, { FC } from 'react';

import FormField from '../../../../Components/FormField';
import { RegisterFormValues } from '../../Register';

type RegisterFormProps = Pick<FormikState<RegisterFormValues>, 'touched' | 'errors'>;

const RegisterForm: FC<RegisterFormProps> = ({ touched, errors }) => {
  return (
    <Form>
      <FormField name="email" type="email" label="Email" disabled />
      <FormField name="firstName" type="text" label="First Name" error={touched.firstName && !!errors.firstName} />
      <FormField name="lastName" type="text" label="Last Name" error={touched.lastName && !!errors.lastName} />
      <FormField name="password" type="password" label="Password" error={touched.password && !!errors.password} />
      <FormField
        name="confirmPassword"
        type="password"
        label="Confirm Password"
        error={touched.confirmPassword && !!errors.confirmPassword}
      />
      <button type="submit">Register</button>
    </Form>
  );
};

export default RegisterForm;

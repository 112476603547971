import { gql, useQuery } from '@apollo/client';
import React, { FC } from 'react';

import Button from '../../Components/Button';
import Card from '../../Components/Card';
import Loading from '../../Components/Loading';
import PageLayout from '../../Components/PageLayout';
import { AccountQuery } from '../../generated/AccountQuery';
import DiscordIntegration, { DiscordIntegrationFragmentGql } from './Components/DiscordIntegration';

const Account: FC = () => {
  const { data, loading } = useQuery<AccountQuery>(AccountGQL);
  console.log(data);
  if (loading || !data) return <Loading />;

  return (
    <PageLayout>
      <Card>
        <h2 className="text-4xl font-bold mb-8">
          {data.currentUser.firstName} {data.currentUser.lastName}
        </h2>
        <h3 className="font-bold">Email</h3>
        <p>{data.currentUser.email}</p>
        <Button color="secondary" className="mt-8" onClick={() => alert("lol i haven't implemented that yet")}>
          Reset your password
        </Button>
      </Card>
      <Card>
        <DiscordIntegration discordUser={data.currentUser.discordUser} />
      </Card>
    </PageLayout>
  );
};

const AccountGQL = gql`
  query AccountQuery {
    currentUser {
      id
      firstName
      lastName
      email
      discordUser {
        id
        ...DiscordIntegrationDiscordUser
      }
    }
  }

  ${DiscordIntegrationFragmentGql}
`;

export default Account;

import { gql, useQuery } from '@apollo/react-hooks';
import React, { FC } from 'react';

import NavHeader, { NavHeaderFragmentGql } from '../NavHeader';

const PageLayout: FC = ({ children }) => {
  const { loading, data } = useQuery(PageLayoutGql);

  if (loading || !data) return null;

  return (
    <>
      <NavHeader user={data.currentUser} />
      <main className="container mx-auto mt-16">{children}</main>
    </>
  );
};

export const PageLayoutGql = gql`
  query PageLayoutUser {
    currentUser {
      id
      ...NavHeaderUser
    }
  }

  ${NavHeaderFragmentGql}
`;

export default PageLayout;

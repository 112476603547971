import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import Card from '../../Components/Card';
import PageLayout from '../../Components/PageLayout';
import InviteUser from './Components/InviteUser';

const Admin: FC = () => {
  return (
    <PageLayout>
      <Helmet>
        <title>Admin</title>
      </Helmet>
      <Card>
        <InviteUser />
      </Card>
    </PageLayout>
  );
};

export default Admin;

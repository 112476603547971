import React, { FC, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

import { ACCOUNT_PATH } from '../../../paths';
import signOut from '../../../utils/signOut';

interface AccountDropdownProps {
  onItemClick: () => void;
}

const AccountDropdown: FC<AccountDropdownProps> = ({ onItemClick }) => {
  const handleSignoutClick: MouseEventHandler = (event) => {
    event.preventDefault();
    signOut();
  };

  return (
    <div className="absolute bg-white right-0 mr-4 rounded shadow text-black">
      <ul>
        <li className="p-4 border-b">
          <Link to={ACCOUNT_PATH} onClick={onItemClick}>
            Account
          </Link>
        </li>
        <li className="p-4 border-b">
          <a
            href={process.env.REACT_APP_QUESTIONNAIRE_LINK}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onItemClick}
          >
            Questionnaire
          </a>
        </li>
        <li className="p-4">
          <button onClick={handleSignoutClick}>Signout</button>
        </li>
      </ul>
    </div>
  );
};

export default AccountDropdown;

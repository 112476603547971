import { gql, useMutation, useQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import React, { FC } from 'react';

import Button from '../../../../Components/Button';
import CheckBox from '../../../../Components/CheckBox';
import {
  UnassignedExchangeCreateAssignments,
  UnassignedExchangeCreateAssignmentsVariables,
} from '../../../../generated/UnassignedExchangeCreateAssignments';
import { UnassignedExchangeExchangeFragment } from '../../../../generated/UnassignedExchangeExchangeFragment';
import { UnassignedExchangeUsers } from '../../../../generated/UnassignedExchangeUsers';

interface UnassignedExchangeProps {
  exchange: UnassignedExchangeExchangeFragment;
}

interface UnassignedExchangeFormValues {
  exchangeId: string;
  userIds: string[];
}

const UnassignedExchange: FC<UnassignedExchangeProps> = ({ exchange }) => {
  const { data, loading } = useQuery<UnassignedExchangeUsers>(UnassignedExchangeUsersGql);
  const [kickOffExchange] = useMutation<
    UnassignedExchangeCreateAssignments,
    UnassignedExchangeCreateAssignmentsVariables
  >(UnassignedExchangeCreateAssignmentsGql, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          exchanges: (existingExchanges) => {
            const newExchangeRef = cache.writeFragment({
              data: data?.createExchangeEntries,
              fragment: gql`
                fragment UnassignedExchangeCreateExchangeCacheFragment on Exchange {
                  id
                  entries {
                    id
                    receiver {
                      id
                      firstName
                      lastName
                    }
                    giver {
                      id
                      firstName
                      lastName
                    }
                  }
                }
              `,
            });
            return [...existingExchanges, newExchangeRef];
          },
        },
      });
    },
  });

  if (loading || !data) {
    return null;
  }

  const handleSubmit = async (values: UnassignedExchangeFormValues) => {
    await kickOffExchange({
      variables: {
        exchangeId: values.exchangeId,
        userIds: values.userIds,
      },
    });
  };

  return (
    <Formik<UnassignedExchangeFormValues>
      initialValues={{
        exchangeId: exchange.id,
        userIds: [],
      }}
      onSubmit={handleSubmit}
    >
      <Form>
        <div role="group" aria-labelledby="checkbox-group">
          {data.users.map((user) => {
            return (
              <CheckBox label={`${user.firstName} ${user.lastName}`} value={user.id} key={user.id} name="userIds" />
            );
          })}
        </div>
        <Button color="primary" type="submit">
          Kick Off
        </Button>
      </Form>
    </Formik>
  );
};

const UnassignedExchangeUsersGql = gql`
  query UnassignedExchangeUsers {
    users {
      id
      firstName
      lastName
    }
  }
`;

const UnassignedExchangeCreateAssignmentsGql = gql`
  mutation UnassignedExchangeCreateAssignments($exchangeId: String!, $userIds: [String!]!) {
    createExchangeEntries(exchangeId: $exchangeId, userIds: $userIds) {
      id
      entries {
        id
        receiver {
          id
          firstName
          lastName
        }
        giver {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const UnassignedExchangeExchangeFragmentGql = gql`
  fragment UnassignedExchangeExchangeFragment on Exchange {
    id
  }
`;

export default UnassignedExchange;

// Login pages
export const LOGIN_PATH = '/login';
export const FORGOT_PASSWORD_PATH = '/forgot_password';
export const PASSWORD_RESET_PATH = '/password_reset/:token';
export const REGISTER_PATH = '/register';

// General pages
export const HOME_PATH = '/';
export const ACCOUNT_PATH = '/account';

// Admin pages
export const ADMIN_PATH = '/admin';
export const EXCHANGE_PATH = '/exchanges';

// Etc
export const SERVER_LOGIN_PATH = `${process.env.REACT_APP_SERVICE_URL}/authenticate`;

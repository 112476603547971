import classNames from 'classnames';
import { ErrorMessage, Field } from 'formik';
import React, { ComponentProps, FC } from 'react';

type FormFieldProps = {
  name: string;
  label: string;
  type: string;
  error?: boolean;
} & ComponentProps<typeof Field>;

const FormField: FC<FormFieldProps> = ({ className, name, label, type, error = false, ...params }) => {
  const errorClass = error ? 'border-red' : 'border-black';
  return (
    <div className={classNames('flex flex-col mb-4', className)}>
      <label htmlFor={name}>{label}</label>
      <Field
        className={classNames('rounded-full border-2 p-2 text-lg focus:outline-none focus:ring', errorClass)}
        name={name}
        id={name}
        type={type}
        {...params}
      />
      <ErrorMessage name={name} />
    </div>
  );
};

export default FormField;

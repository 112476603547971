import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import BordenApolloProvider from './Components/BordenApolloProvider';
import Routes from './Routes';

const App: FC = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <BordenApolloProvider>
          <Routes />
        </BordenApolloProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default App;

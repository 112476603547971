import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { number, object, string } from 'yup';

import Button from '../../../Components/Button';
import FormField from '../../../Components/FormField';

interface ExchangeCreateFormProps {
  onSubmit: (values: ExchangeCreateFormValues) => Promise<void>;
  onCancel: () => void;
}

export interface ExchangeCreateFormValues {
  name: string;
  limit: number;
}

const ExchangeCreateForm: FC<ExchangeCreateFormProps> = ({ onSubmit, onCancel }) => {
  return (
    <Formik<ExchangeCreateFormValues>
      initialValues={{
        name: '',
        limit: 0,
      }}
      onSubmit={onSubmit}
      validationSchema={object().shape({
        name: string().required(),
        limit: number().positive().required(),
      })}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <div className="flex w-full justify-between">
              <FormField className="w-1/2 pr-4" name="name" label="Exchange name" />
              <FormField className="w-1/2 pl-4" name="limit" label="Limit($)" type="number" min="0" step="0.01" />
            </div>
            <Button color="destructive" className="mr-2" onClick={onCancel}>
              Cancel
            </Button>
            <Button color="primary" type="submit" submitting={isSubmitting}>
              Submit
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ExchangeCreateForm;

interface User {
  firstName: string | null;
  lastName: string | null;
}

const fullNameForUser = (user: User): string => {
  return [user.firstName, user.lastName].join(' ');
};

export default fullNameForUser;

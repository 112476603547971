import { gql } from '@apollo/client';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import Table from '../../../Components/Table';
import { ExchangeCardFragment } from '../../../generated/ExchangeCardFragment';
import { EXCHANGE_PATH } from '../../../paths';
import centsToCurrency from '../../../utils/centsToCurrency';

interface ExchangeTableProps {
  loading: boolean;
  exchanges: ExchangeCardFragment[] | undefined;
}

const ExchangeTable: FC<ExchangeTableProps> = ({ loading, exchanges }) => {
  return (
    <Table
      loading={loading}
      entries={exchanges || []}
      columns={[
        {
          name: 'Exchange Name',
          renderCell(entry) {
            return <Link to={`${EXCHANGE_PATH}/${entry.id}`}>{entry.name}</Link>;
          },
        },
        { name: 'Year', value: 'year' },
        { name: 'Spending Limit', value: 'limitInCents', transform: centsToCurrency },
      ]}
    />
  );
};

export const ExchangeTableFragmentGql = gql`
  fragment ExchangeTableFragment on Exchange {
    id
    name
    year
    limitInCents
  }
`;

export default ExchangeTable;

import React, { FC } from 'react';

import PageLayout from '../PageLayout';

interface LoadingProps {
  includeLayout?: boolean;
}

const Loading: FC<LoadingProps> = ({ includeLayout = false }) => {
  if (includeLayout) {
    return (
      <PageLayout>
        <p>Loading...</p>
      </PageLayout>
    );
  }

  return <p>Loading...</p>;
};

export default Loading;

import { Form, Formik } from 'formik';
import React, { FC } from 'react';

import Button from '../../../Components/Button';
import FormField from '../../../Components/FormField';

interface Props {
  initialValues: PasswordResetFormValues;
  onSubmit: (values: PasswordResetFormValues) => Promise<void>;
}

export interface PasswordResetFormValues {
  token: string;
  newPassword: string;
}

const PasswordResetForm: FC<Props> = ({ initialValues, onSubmit }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ touched, errors, isSubmitting }) => {
        return (
          <Form className="flex flex-col max-w-md mx-auto">
            <FormField
              name="newPassword"
              type="text"
              label="New Password"
              error={touched.newPassword && !!errors.newPassword}
            />
            <Button color="primary" type="submit" submitting={isSubmitting}>
              Reset password
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PasswordResetForm;

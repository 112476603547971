import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { PrivateAdminRoute, PrivateRoute } from './Components/PrivateRoute';
import Account from './Pages/Account';
import Admin from './Pages/Admin';
import Exchange from './Pages/Exchange';
import Exchanges from './Pages/Exchanges';
import ForgotPassword from './Pages/ForgotPassword';
import Home from './Pages/Home';
import Login from './Pages/Login';
import NotFound from './Pages/NotFound';
import PasswordReset from './Pages/PasswordReset';
import Register from './Pages/Register';
import {
  ACCOUNT_PATH,
  ADMIN_PATH,
  EXCHANGE_PATH,
  FORGOT_PASSWORD_PATH,
  HOME_PATH,
  LOGIN_PATH,
  PASSWORD_RESET_PATH,
  REGISTER_PATH,
} from './paths';

const Routes: FC = () => {
  return (
    <Switch>
      <Route path={FORGOT_PASSWORD_PATH} exact component={ForgotPassword} />
      <Route path={PASSWORD_RESET_PATH} exact component={PasswordReset} />
      <Route path={LOGIN_PATH} exact component={Login} />
      <Route path={REGISTER_PATH} exact component={Register} />
      <PrivateRoute exact path={ACCOUNT_PATH} component={Account} />
      <PrivateAdminRoute exact path={ADMIN_PATH} component={Admin} />

      <PrivateAdminRoute exact path={EXCHANGE_PATH} component={Exchanges} />
      <PrivateAdminRoute exact path={`${EXCHANGE_PATH}/new`} component={Exchanges} />
      <PrivateAdminRoute exact path={`${EXCHANGE_PATH}/:id`} component={Exchange} />

      <PrivateRoute exact path={HOME_PATH} component={Home} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;

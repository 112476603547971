import { useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { object, string } from 'yup';

import CenteredSheetLayout from '../../Components/CenteredSheetLayout';
import Loading from '../../Components/Loading';
import { AUTH_TOKEN } from '../../Components/PrivateRoute/PrivateRoute';
import { LoginCurrentUserQuery } from '../../generated/LoginCurrentUserQuery';
import { FORGOT_PASSWORD_PATH, HOME_PATH, SERVER_LOGIN_PATH } from '../../paths';
import LoginForm from './LoginForm';

export interface LoginFormValues {
  email: string;
  password: string;
}

const Login: FC = () => {
  const [loginFailure, setLoginFailure] = useState(false);
  const history = useHistory();
  const { loading, data } = useQuery<LoginCurrentUserQuery>(LoginCurrentUserGQL);

  const handleSubmit = async (values: LoginFormValues) => {
    setLoginFailure(false);
    try {
      const response = await fetch(SERVER_LOGIN_PATH, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(values),
      });
      if (response.ok) {
        const { token } = await response.json();
        localStorage.setItem(AUTH_TOKEN, token);
        history.push(HOME_PATH);
      } else {
        setLoginFailure(true);
      }
    } catch {
      setLoginFailure(true);
    }
  };

  if (loading) return <Loading />;
  if (data?.currentUser) return <Redirect to={HOME_PATH} />;

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <CenteredSheetLayout header="Sign in">
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={handleSubmit}
          validationSchema={object().shape({
            email: string().required(),
            password: string().required(),
          })}
        >
          <LoginForm loginFailed={loginFailure} />
        </Formik>
        <Link to={FORGOT_PASSWORD_PATH}>Forgot password</Link>
      </CenteredSheetLayout>
    </>
  );
};

export const LoginCurrentUserGQL = gql`
  query LoginCurrentUserQuery {
    currentUser {
      id
      admin
    }
  }
`;

export default Login;

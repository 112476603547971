import { Form, useFormikContext } from 'formik';
import React, { FC } from 'react';

import Button from '../../Components/Button';
import FormField from '../../Components/FormField';
import { LoginFormValues } from './Login';

type LoginFormProps = {
  loginFailed: boolean;
};

const LoginForm: FC<LoginFormProps> = ({ loginFailed }) => {
  const { touched, errors, isSubmitting } = useFormikContext<LoginFormValues>();
  return (
    <Form className="flex flex-col pt-8 max-w-md mx-auto">
      {loginFailed && <p className="pb-2 text-red">Login failed. If this keeps happening let me know</p>}
      <FormField name="email" type="text" label="Email" error={touched.email && !!errors.email} />
      <FormField name="password" type="password" label="Password" error={touched.password && !!errors.password} />
      <Button color="primary" type="submit" submitting={isSubmitting}>
        Sign In
      </Button>
    </Form>
  );
};

export default LoginForm;

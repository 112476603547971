import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { object, string } from 'yup';

import Button from '../../../Components/Button';
import FormField from '../../../Components/FormField';

interface Props {
  onSubmit: (values: ForgotPasswordFormValues) => void;
}

export interface ForgotPasswordFormValues {
  email: string;
}

const ForgotPasswordForm: FC<Props> = ({ onSubmit }) => {
  return (
    <>
      <p>
        Enter your email below. If an account exists with the provided email, a password reset link will be emailed to
        you shortly.
      </p>

      <Formik<ForgotPasswordFormValues>
        initialValues={{ email: '' }}
        onSubmit={onSubmit}
        validationSchema={object().shape({
          email: string().required(),
        })}
      >
        {({ touched, isSubmitting, errors }) => {
          return (
            <Form className="flex flex-col pt-8 max-w-md mx-auto">
              <FormField name="email" type="text" label="Email" error={touched.email && !!errors.email} />
              <Button color="primary" type="submit" submitting={isSubmitting}>
                Submit
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ForgotPasswordForm;

import { gql, useMutation } from '@apollo/client';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';

import CenteredSheetLayout from '../../Components/CenteredSheetLayout';
import {
  ForgotPasswordCreatePasswordReset,
  ForgotPasswordCreatePasswordResetVariables,
} from '../../generated/ForgotPasswordCreatePasswordReset';
import ForgotPasswordForm, { ForgotPasswordFormValues } from './Components/ForgotPasswordForm';

const ForgotPassword: FC = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [createPasswordReset] = useMutation<
    ForgotPasswordCreatePasswordReset,
    ForgotPasswordCreatePasswordResetVariables
  >(ForgotPasswordCreatePasswordResetGql);

  const handleSubmit = async ({ email }: ForgotPasswordFormValues) => {
    await createPasswordReset({ variables: { email } });
    setFormSubmitted(true);
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <CenteredSheetLayout header="Forgot Password">
        {formSubmitted ? (
          <p>
            Thank you for submitting. If the email provided matches an account on record, you should receive a reset
            link email shortly
          </p>
        ) : (
          <ForgotPasswordForm onSubmit={handleSubmit} />
        )}
      </CenteredSheetLayout>
    </>
  );
};

const ForgotPasswordCreatePasswordResetGql = gql`
  mutation ForgotPasswordCreatePasswordReset($email: String!) {
    createPasswordReset(email: $email)
  }
`;

export default ForgotPassword;
